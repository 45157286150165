import React, {FormEvent, useEffect, useState, useCallback, useMemo} from 'react';
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {Input} from "../models";
import {matchPath, useLocation} from "react-router-dom";
import {useInputData} from "./Hooks";

import './InputForm.scss';
import {SINGLE_AZ, SINGLE_AZ_2, MULTI_AZ, MULTI_AZ_2} from "./Constants";

function InputForm(props: { input: Input, onInputDecoded: (arg0: Input) => void, onSubmit: (arg0: Input) => void }) {

    const [validated, setValidated] = useState<boolean>(false);
    const [region, setRegion] = useState<string>(props.input.region);
    const [hddStorageCapacity, setHddStorageCapacity] = useState<string>(props.input.storage.data === 0 ? '' : String(props.input.storage.data));
    const [ssdStorageCapacity, setSsdStorageCapacity] = useState<string>(String(props.input.storage.ssd));
    const [ssdStorageSavings, setSsdStorageSavings] = useState<string>(String(props.input.storage.ssdStorageSavings));
    // const [capacityPoolSavings, setCapacityPoolSavings] = useState<string>(String(props.input.storage.capacityPoolSavings));
    const [provisionedSsdIops, setProvisionedSsdIops] = useState<string>(props.input.storage.provisionedSsdIops ? String(props.input.storage.provisionedSsdIops) : '');
    const [provisionedThroughput, setProvisionedThroughput] = useState<string>(String(props.input.storage.throughput));
    const [backups, setBackups] = useState<string>(String(props.input.storage.backups));

    const [deploymentType, setDeploymentType] = useState<string>(props.input.storage.deploymentType || "Multi-AZ");

    const [isProvisionedSsdIopsAutomatic, setIsProvisionedSsdIopsAutomatic] = useState<boolean>(props.input.storage.provisionedSsdIops === null);
    // const [showDedupe, setShowDedupe] = useState<boolean>(false);
    const [dedupeStorageUtilization, setDedupeStorageUtilization] = useState<string>(String(props.input.storage.utilization));

    const [refreshTimeline, setRefreshTimeline] = useState<string>(props.input.onPrem.hardwareDepreciation === 0 ? '' : String(props.input.onPrem.hardwareDepreciation));
    const [storageGrowth, setStorageGrowth] = useState<string>(props.input.onPrem.storageGrowth === 0 ? '' : String(props.input.onPrem.storageGrowth));

    const location = useLocation();
    const {decode} = useInputData();
    const [unsupportedScaleOutRegionError, setUnsupportedScaleOutRegionError] = useState<boolean>(false);
    const [unsupportedMaz2RegionError, setUnsupportedMaz2RegionError] = useState<boolean>(false);
    const scaleOutSupportedRegions = useMemo(() =>[
        'eu-west-1',
        'us-east-1',
        'us-east-2',
        'us-west-2',
        'ap-southeast-2',
        'eu-central-1'
    ], []);
    const maz2SupportedRegions = useMemo(() =>[
        'eu-west-1',
        'us-east-1',
        'us-east-2',
        'us-west-2',
        'ap-southeast-2',
        'eu-central-1',
        'eu-west-1'
    ], []);

    const deploymentTypeToSupportedThroughputCapacities = new Map<string, number[]>([
        [SINGLE_AZ, [128, 256, 512, 1024, 2048, 4096]],
        [SINGLE_AZ_2, [384, 768, 1536, 3072, 6144, 7680, 9216, 10752, 12288, 13824, 15360, 16896, 18432, 21504, 24576,
            27648, 30720, 33792, 36864, 43008, 49152, 55292, 61440, 67584, 73728]],
        [MULTI_AZ, [128, 256, 512, 1024, 2048, 4096]],
        [MULTI_AZ_2, [384, 768, 1536, 3072, 6144]]
    ]);

    const throughputCapacityRenderOptions = () => {
        const supportedThroughputCapacities =
            deploymentTypeToSupportedThroughputCapacities.get(deploymentType) || [];

        if (supportedThroughputCapacities.length === 0) {
            return (
                <>
                    <option value="" disabled>
                        Select Throughput
                    </option>
                </>
            );
        }

        return supportedThroughputCapacities.map((capacity) => (
            <option key={capacity} value={capacity}>
                {capacity.toLocaleString()}
            </option>
        ));
    };

    const isUnsupportedMaz2Region = useCallback(() => {
        return deploymentType === MULTI_AZ_2 && !maz2SupportedRegions.includes(region);
    }, [deploymentType, maz2SupportedRegions, region]);

    const isUnsupportedScaleOutRegion = useCallback(() => {
        return deploymentType === SINGLE_AZ_2 && !scaleOutSupportedRegions.includes(region);
    }, [deploymentType, scaleOutSupportedRegions, region]);

    useEffect(() => {
        setUnsupportedScaleOutRegionError(isUnsupportedScaleOutRegion())
        setUnsupportedMaz2RegionError(isUnsupportedMaz2Region())
    }, [region, deploymentType, isUnsupportedScaleOutRegion, isUnsupportedMaz2Region]);

    useEffect(() => {
        if (isProvisionedSsdIopsAutomatic) {
            setProvisionedSsdIops('');
        }
    }, [isProvisionedSsdIopsAutomatic]);

    useEffect(() => {
        const matchedPath = matchPath<{ token: string }>(location.pathname, {
            path: "/:token",
            exact: true,
            strict: false
        });

        if (!matchedPath?.params?.token) {
            return;
        }

        const decodedInput = decode(matchedPath.params.token);
        setRegion(decodedInput.region);
        setHddStorageCapacity(String(decodedInput.storage.data));
        setSsdStorageCapacity(String(decodedInput.storage.ssd));
        setSsdStorageSavings(String(decodedInput.storage.ssdStorageSavings));
        // setCapacityPoolSavings(String(decodedInput.storage.capacityPoolSavings));
        setProvisionedSsdIops(decodedInput.storage.provisionedSsdIops !== null ? String(decodedInput.storage.provisionedSsdIops) : '');
        setProvisionedThroughput(String(decodedInput.storage.throughput));
        setBackups(String(decodedInput.storage.backups))
        setDeploymentType(decodedInput.storage.deploymentType);

        setDedupeStorageUtilization(String(decodedInput.storage.utilization));
        setRefreshTimeline(String(decodedInput.onPrem.hardwareDepreciation));
        setStorageGrowth(String(decodedInput.onPrem.storageGrowth));
// eslint-disable-next-line
    }, [location]);

    // TODO: Is backup part of the deal?
    // const [showBackup, setShowBackup] = useState<boolean>(props.input.backup.enabled);
    // const [backupCapacity, setBackupCapacity] = useState<string>(String(props.input.backup.amount));
    // const [backupUnit, setBackupUnit] = useState<SizeUnit>(props.input.backup.unit);

    const onSubmit = (event: FormEvent) => {
        setValidated(true);
        //We don't want to reset the inputs to default values on submit
        event.preventDefault();
        const form = event.currentTarget as any;
        if (form && form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        if (isUnsupportedMaz2Region()) {
            setUnsupportedMaz2RegionError(true);
            setValidated(false);
            return;
        } else {
            setUnsupportedMaz2RegionError(false);
        }

        if (isUnsupportedScaleOutRegion()) {
            setUnsupportedScaleOutRegionError(true);
            setValidated(false);
            return;
        } else {
            setUnsupportedScaleOutRegionError(false);
        }

        const input: Input = {
            region: region,
            storage: {
                data: parseInt(hddStorageCapacity),
                ssd: parseInt(ssdStorageCapacity),
                ssdStorageSavings: parseInt(ssdStorageSavings),
                // capacityPoolSavings: parseInt(capacityPoolSavings),
                provisionedSsdIops: isProvisionedSsdIopsAutomatic ? null : parseInt(provisionedSsdIops),
                throughput: parseInt(provisionedThroughput),
                backups: parseInt(backups),
                // multiAz: isMultiAz,
                multiAz: (deploymentType ?? '').startsWith("Multi"),
                deploymentType: (deploymentType),
                utilization: parseInt(dedupeStorageUtilization, 10)
            },
            onPrem: {
                hardwareDepreciation: parseInt(refreshTimeline),
                storageGrowth: parseInt(storageGrowth)
            }
        };
        props.onSubmit(input);
    }

    const handleDeploymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newDeploymentType = e.target.value;
        setDeploymentType(newDeploymentType);

        // Calculate new throughput options based on the new deployment type
        const supportedThroughputCapacities: number[] =
            deploymentTypeToSupportedThroughputCapacities.get(newDeploymentType) || [];

        // Check if the current provisionedThroughput is in the new options list
        if (!supportedThroughputCapacities.includes(Number(provisionedThroughput))) {
            // If it's not in the list, set it to the first option in the new list
            // or to an empty string if there are no options
            const newProvisionedThroughput = String(supportedThroughputCapacities[0] || props.input.storage.throughput);
            setProvisionedThroughput(newProvisionedThroughput);
        }
        // If the current provisionedThroughput is in the new list, we don't need to change it
    };

    // When updating region list below, also update
    // TsoStorageCalculatorRequestHandler/src/tso_storage_calculator_request_handler/aws_prices.py
    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}
              className="form d-flex flex-column justify-content-between">
            <Container fluid className="form-container">
                <Row className="mt-4">
                    <Col xs={12}>
                        <h5 className="font-weight-bold">Configure Amazon FSx for NetApp ONTAP</h5>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Form.Group as={Col} controlId="region">
                                <Form.Label>Region</Form.Label>
                                <Form.Control as="select" required value={region}
                                              isInvalid={unsupportedMaz2RegionError || unsupportedScaleOutRegionError}
                                              onChange={e => setRegion(e.target.value)}>
                                    <option disabled value=""> --- select an option ---</option>
                                    <option value="us-east-2">US East (Ohio)</option>
                                    <option value="us-west-1">US West (N. California)</option>
                                    <option value="us-east-1">US East (N. Virginia)</option>
                                    <option value="us-west-2">US West (Oregon)</option>
                                    <option value="af-south-1">Africa (Cape Town)</option>
                                    <option value="ap-east-1">Asia Pacific (Hong Kong)</option>
                                    <option value="ap-south-2">Asia Pacific (Hyderabad)</option>
                                    <option value="ap-southeast-3">Asia Pacific (Jakarta)</option>
                                    <option value="ap-southeast-4">Asia Pacific (Melbourne)</option>
                                    <option value="ap-south-1">Asia Pacific (Mumbai)</option>
                                    <option value="ap-northeast-2">Asia Pacific (Seoul)</option>
                                    <option value="ap-southeast-1">Asia Pacific (Singapore)</option>
                                    <option value="ap-southeast-2">Asia Pacific (Sydney)</option>
                                    <option value="ap-northeast-1">Asia Pacific (Tokyo)</option>
                                    <option value="ca-central-1">Canada (Central)</option>
                                    <option value="eu-central-1">Europe (Frankfurt)</option>
                                    <option value="eu-west-1">Europe (Ireland)</option>
                                    <option value="eu-west-2">Europe (London)</option>
                                    <option value="eu-south-1">Europe (Milan)</option>
                                    <option value="eu-west-3">Europe (Paris)</option>
                                    <option value="eu-south-2">Europe (Spain)</option>
                                    <option value="eu-north-1">Europe (Stockholm)</option>
                                    <option value="eu-central-2">Europe (Zurich)</option>
                                    <option value="il-central-1">Israel (Tel Aviv)</option>
                                    <option value="me-south-1">Middle East (Bahrain)</option>
                                    <option value="me-central-1">Middle East (UAE)</option>
                                    <option value="sa-east-1">South America (São Paulo)</option>
                                    <option value="us-gov-east-1">AWS GovCloud (US-East)</option>
                                    <option value="us-gov-west-1">AWS GovCloud (US-West)</option>
                                </Form.Control>
                                <Form.Control.Feedback type={"invalid"}>
                                    {(() => {
                                        switch (true) {
                                            case unsupportedMaz2RegionError:
                                                return "The Multi-AZ-2 deployment type is not supported in the selected AWS region.";
                                            case unsupportedScaleOutRegionError:
                                                return "The Single-AZ-2 deployment type is not supported in the selected AWS region.";
                                            default:
                                                return "Please select a region";
                                        }
                                    })()}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="deploymentType">
                                <Form.Label>Deployment type</Form.Label>
                                <Form.Control as="select" required value={deploymentType}
                                              onChange={handleDeploymentTypeChange}>
                                    <option value={SINGLE_AZ}>Single-AZ</option>
                                    <option value={SINGLE_AZ_2}>Single-AZ-2</option>
                                    <option value={MULTI_AZ}>Multi-AZ</option>
                                    <option value={MULTI_AZ_2}>Multi-AZ-2</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="fsx-storage-capacity-hdd">
                                <Form.Label>Total storage capacity</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={hddStorageCapacity}
                                                  onChange={e => setHddStorageCapacity(e.target.value)} required
                                                  min={1}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>TiB</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type={"invalid"}>
                                        Please enter a positive number
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Average amount of storage capacity provisioned for your file systems per month
                                </Form.Text>
                            </Form.Group>

                            <Form.Group as={Col} controlId="fsx-storage-capacity-ssd">
                                <Form.Label>Percentage of data on SSD storage</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={ssdStorageCapacity}
                                                  onChange={e => setSsdStorageCapacity(e.target.value)} required min={5}
                                                  max={100}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type={"invalid"}>
                                        Please enter a number between 5 and 100
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Percentage of SSD capacity relative to total capacity
                                </Form.Text>
                            </Form.Group>
                        </Row>

                        <fieldset className="mt-2 pt-3">
                            <legend className="h5 mb-1">Compression and deduplication savings</legend>
                            <small className="text-muted d-block mb-3">
                                Typical savings for general-purpose file shares are 65% with compression + deduplication
                                enabled.
                                See the <a href="https://aws.amazon.com/fsx/netapp-ontap/faqs/">Amazon FSx for NetApp ONTAP FAQ</a> for
                                more information.
                            </small>
                            <Row>
                                <Form.Group as={Col} controlId="fsx-ssd-storage-savings">
                                    <Form.Label>Savings from compression + deduplication</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="number" value={ssdStorageSavings}
                                                      onChange={e => setSsdStorageSavings(e.target.value)}
                                                      required min={0} max={80}/>
                                        <InputGroup.Append>
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup.Append>
                                        <Form.Control.Feedback type={"invalid"}>
                                            Please enter a number between 0 and 80.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        SSD storage, capacity pool storage, and backups support compression and deduplication.
                                    </Form.Text>
                                </Form.Group>

                                {/*<Form.Group as={Col} controlId="fsx-capacity-pool-savings">*/}
                                {/*    <Form.Label>Savings from deduplication only</Form.Label>*/}
                                {/*    <InputGroup>*/}
                                {/*        <Form.Control type="number" value={capacityPoolSavings}*/}
                                {/*                      onChange={e => setCapacityPoolSavings(e.target.value)}*/}
                                {/*                      required min={0} max={80}/>*/}
                                {/*        <InputGroup.Append>*/}
                                {/*            <InputGroup.Text>%</InputGroup.Text>*/}
                                {/*        </InputGroup.Append>*/}
                                {/*        <Form.Control.Feedback type={"invalid"}>*/}
                                {/*            Please enter a number between 0 and 80.*/}
                                {/*        </Form.Control.Feedback>*/}
                                {/*    </InputGroup>*/}
                                {/*    <Form.Text className="text-muted">*/}
                                {/*        Capacity pool storage supports deduplication.*/}
                                {/*    </Form.Text>*/}
                                {/*</Form.Group>*/}
                            </Row>

                            <Form.Group>
                                <Form.Label>Provisioned SSD IOPS</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="fsx-provisioned-ssd-iops-automatic"
                                    name="fsx-provisioned-ssd-iops-automatic"
                                    label="Automatic (3 IOPS per GB of SSD storage)"
                                    checked={isProvisionedSsdIopsAutomatic}
                                    onChange={() => setIsProvisionedSsdIopsAutomatic(!isProvisionedSsdIopsAutomatic)}
                                />
                            </Form.Group>

                            {!isProvisionedSsdIopsAutomatic && (
                                <Form.Group controlId="fsx-provisioned-ssd-iops" className="mt-3">
                                    <Form.Control type="number" value={provisionedSsdIops}
                                                  disabled={isProvisionedSsdIopsAutomatic}
                                                  placeholder="User-provisioned"
                                                  onChange={e => setProvisionedSsdIops(e.target.value)}
                                                  required min={0} />
                                    <Form.Control.Feedback type={"invalid"}>
                                        Please enter a positive number.
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        Average amount of SSD IOPS provisioned for your file systems per month.
                                    </Form.Text>
                                </Form.Group>
                            )}
                        </fieldset>

                        <Row className="align-items-center">
                            <Form.Group as={Col} controlId="fsx-provisioned-throughput">
                                <Form.Label>Throughput capacity</Form.Label>
                                <InputGroup>
                                    <Form.Control as="select"  type="number" value={provisionedThroughput}
                                                  onChange={e => {
                                                      console.log(`on TC changed, e.target.value = ${e.target.value}`)
                                                      setProvisionedThroughput(e.target.value);
                                                  }}
                                                  required={true}>
                                        {throughputCapacityRenderOptions()}
                                    </Form.Control>
                                    <InputGroup.Append>
                                        <InputGroup.Text>MB/s</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Enter the aggregate throughput capacity you want to provision. Each Amazon FSx for NetApp ONTAP file system supports up to 36,864 MB/s of throughput capacity.
                                </Form.Text>
                                {/*Multi AZ is enabled by default now*/}
                                {/*<Form.Text className="text-muted">*/}
                                {/*    Note that the pricing depends on the type of deployment (Single-AZ vs Mutli-AZ)*/}
                                {/*</Form.Text>*/}
                            </Form.Group>

                            {/*This is commented out on request from AWS. Uncomment to make the user interact with single vs multi AZ*/}
                            {/*<Form.Group as={Col} controlId="fsx-enable-multi-az">*/}
                            {/*    <Form.Check*/}
                            {/*        custom*/}
                            {/*        type="checkbox"*/}
                            {/*        id="fsx-multi-az"*/}
                            {/*        className="mb-3"*/}
                            {/*        label="Enable Multi-AZ"*/}
                            {/*        checked={isMultiAz}*/}
                            {/*        onChange={() => setIsMultiAz(!isMultiAz)}*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}
                        </Row>

                        <Row className="align-items-center">
                            <Form.Group as={Col} controlId="fsx-provisioned-throughput">
                                <Form.Label>Backup storage</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={backups}
                                                  onChange={e => setBackups(e.target.value)} required
                                                  min={0} />
                                    <InputGroup.Append>
                                        <InputGroup.Text>TiB</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type={"invalid"}>
                                        Please enter a positive number, greater or equal to 0.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    Average amount of backup storage consumed for your file systems per month.
                                </Form.Text>
                            </Form.Group>
                        </Row>

                        {/*<Form.Check*/}
                        {/*    custom*/}
                        {/*    type="switch"*/}
                        {/*    id="fsx-dedupe-show"*/}
                        {/*    className="mb-3"*/}
                        {/*    label="Advanced"*/}
                        {/*    checked={showDedupe}*/}
                        {/*    onChange={() => setShowDedupe(!showDedupe)}*/}
                        {/*/>*/}

                        {/*{showDedupe && (*/}
                        {/*    <Row>*/}
                        {/*        <Form.Group as={Col} controlId="fsx-dedupe-efficiency-saving">*/}
                        {/*            <Form.Label>Storage efficiency savings</Form.Label>*/}
                        {/*            <InputGroup>*/}
                        {/*                <Form.Control type="number" value={dedupeEfficiencySavings}*/}
                        {/*                              onChange={e => setDedupeEfficiencySavings(e.target.value)}*/}
                        {/*                              required min={0} max={80}/>*/}
                        {/*                <InputGroup.Append>*/}
                        {/*                    <InputGroup.Text>%</InputGroup.Text>*/}
                        {/*                </InputGroup.Append>*/}
                        {/*                <Form.Control.Feedback type={"invalid"}>*/}
                        {/*                    Please enter a number between 0 and 80*/}
                        {/*                </Form.Control.Feedback>*/}
                        {/*            </InputGroup>*/}
                        {/*        </Form.Group>*/}

                        {/*        /!*<Form.Group as={Col} controlId="fsx-dedupe-storage-utilization">*!/*/}
                        {/*        /!*    <Form.Label>Target storage utilization (used vs. usable capacity)</Form.Label>*!/*/}
                        {/*        /!*    <InputGroup>*!/*/}
                        {/*        /!*        <Form.Control type="number" value={dedupeStorageUtilization}*!/*/}
                        {/*        /!*                      onChange={e => setDedupeStorageUtilization(e.target.value)}*!/*/}
                        {/*        /!*                      required min={1} max={100}/>*!/*/}
                        {/*        /!*        <InputGroup.Append>*!/*/}
                        {/*        /!*            <InputGroup.Text>%</InputGroup.Text>*!/*/}
                        {/*        /!*        </InputGroup.Append>*!/*/}
                        {/*        /!*        <Form.Control.Feedback type={"invalid"}>*!/*/}
                        {/*        /!*            Please enter a number between 1 and 100*!/*/}
                        {/*        /!*        </Form.Control.Feedback>*!/*/}
                        {/*        /!*    </InputGroup>*!/*/}
                        {/*        /!*</Form.Group>*!/*/}
                        {/*    </Row>*/}
                        {/*)}*/}

                        {/* TODO: Is backup part of the deal? */}
                        {/*<Form.Check*/}
                        {/*    custom*/}
                        {/*    type="switch"*/}
                        {/*    id="fsx-backup-enable"*/}
                        {/*    className="mb-4"*/}
                        {/*    label="Enable backup"*/}
                        {/*    checked={showBackup}*/}
                        {/*    onChange={() => setShowBackup(!showBackup)}*/}
                        {/*/>*/}

                        {/*{showBackup && (*/}
                        {/*    <Row>*/}
                        {/*        <Form.Group as={Col} sm={12} md={6} controlId="fsx-dedupe-amount">*/}
                        {/*            <InputGroup>*/}
                        {/*                <Form.Control type="number" value={backupCapacity}*/}
                        {/*                              onChange={e => setBackupCapacity(e.target.value)}*/}
                        {/*                              required={showBackup} min={0}*/}
                        {/*                              max={backupUnit === 'GB' ? hddStorageCapacity : 100}/>*/}
                        {/*                <InputGroup.Append>*/}
                        {/*                    <Button variant={backupUnit === 'GB' ? 'secondary' : 'outline-secondary'}*/}
                        {/*                            onClick={() => setBackupUnit('GB')}>GB</Button>*/}
                        {/*                    <Button variant={backupUnit === '%' ? 'secondary' : 'outline-secondary'}*/}
                        {/*                            onClick={() => setBackupUnit('%')}>%</Button>*/}
                        {/*                </InputGroup.Append>*/}
                        {/*                <Form.Control.Feedback type={"invalid"}>*/}
                        {/*                    Please enter a number between 0*/}
                        {/*                    and {backupUnit === 'GB' ? `${hddStorageCapacity || '–unknown–'} (storage capacity)` : 100}*/}
                        {/*                </Form.Control.Feedback>*/}
                        {/*            </InputGroup>*/}
                        {/*        </Form.Group>*/}
                        {/*    </Row>*/}
                        {/*)}*/}
                    </Col>
                </Row>

                {/*Commented out on September 8th, 2021: To deliver a simplified version of the calculator*/}
                {/*<Row className="border-top mt-4 pt-3">*/}
                {/*    <Col xs={12}>*/}
                {/*        <h5 className="font-weight-bold">Step 2: To accurately calculate your potential savings, the*/}
                {/*            following information about your on-premises setup is required</h5>*/}
                {/*    </Col>*/}
                {/*    <Col xs={12} className="mt-4">*/}
                {/*        <div>*/}
                {/*            <Row>*/}
                {/*                <Form.Group as={Col} controlId="on-prem">*/}
                {/*                    <Form.Label>Refresh timeline</Form.Label>*/}
                {/*                    <InputGroup>*/}
                {/*                        <Form.Control type="number" value={refreshTimeline}*/}
                {/*                                      onChange={e => setRefreshTimeline(e.target.value)} required*/}
                {/*                                      min={36}*/}
                {/*                                      max={60}/>*/}
                {/*                        <InputGroup.Append>*/}
                {/*                            <InputGroup.Text>Months</InputGroup.Text>*/}
                {/*                        </InputGroup.Append>*/}
                {/*                        <Form.Control.Feedback type={"invalid"}>*/}
                {/*                            Please enter a number between 36 and 60*/}
                {/*                        </Form.Control.Feedback>*/}
                {/*                    </InputGroup>*/}
                {/*                </Form.Group>*/}

                {/*                <Form.Group as={Col} controlId="fsx-ssd-capacity" className="mb-3">*/}
                {/*                    <Form.Label>Annual storage growth</Form.Label>*/}
                {/*                    <InputGroup>*/}
                {/*                        <Form.Control type="number" value={storageGrowth}*/}
                {/*                                      onChange={e => setStorageGrowth(e.target.value)} required*/}
                {/*                                      min={0} max={200}/>*/}
                {/*                        <InputGroup.Append>*/}
                {/*                            <InputGroup.Text>%</InputGroup.Text>*/}
                {/*                        </InputGroup.Append>*/}
                {/*                        <Form.Control.Feedback type={"invalid"}>*/}
                {/*                            Please enter a number between 0 and 200*/}
                {/*                        </Form.Control.Feedback>*/}
                {/*                    </InputGroup>*/}
                {/*                </Form.Group>*/}
                {/*            </Row>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Container>
            <div className="flex-shrink-0 pt-3 pb-2 border-top">
                <Button variant={"primary"} type={"submit"} className="btn-block">Calculate</Button>
                <small className="font-italic text-muted mt-3">Please note: This tool provides only an estimate of fees
                    and savings based on certain information you provide. Estimates do not include taxes that might
                    apply.
                    Your actual fees and savings depend on a variety of factors, including you actual usage of AWS
                    services,
                    which may vary from the estimates provided in the results of this tool.</small>
            </div>
        </Form>
    );
}

export default InputForm;

